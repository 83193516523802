@use "styles/mixins.scss";

.btn-base {
    @include mixins.borders;
    @include mixins.typography;
    @include mixins.focusable;

    align-items: center;
    display: inline-flex;
    justify-content: center;

    &.ant-btn-default {
        background: white;
    }

    &.ant-btn-primary:not(.ant-btn-dangerous.ant-btn-primary):not(.ant-btn-background-ghost):not(:disabled) {
        background: var(--light-mint);
        border-color: var(--dark-mint);
        color: white;

        span {
            color: white;
        }

        &:hover,
        &:focus,
        &:active {
            background: var(--light-mint-hovered);
            border-color: var(--dark-mint);
            color: white;
        }
    }

    .anticon {
        display: inline-grid;
    }
}

.btn {
    @include mixins.shadowed;
}

.ant-btn {
    @extend .btn-base;
}
