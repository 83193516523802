.ant-input {
    border-radius: var(--border-radius);
    font-size: var(--default-font-size);
}

.ant-input,
.ant-picker,
.ant-input-affix-wrapper {
    outline: 2px solid transparent !important;
    outline-offset: 2px;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: white;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow) !important;
    z-index: 2;
}

.ant-input-affix-wrapper {
    .ant-input {
        outline: none !important;
        box-shadow: none !important;
    }
}

.ant-input:focus,
.ant-input-focused,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-input:focus,
.ant-input-focused,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-picker-focused,
.ant-picker:hover,
.ant-picker-focused {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity));
    --tw-ring-color: var(--light-mint);
    --tw-ring-offset-color: var(--light-mint);
    border-color: var(--light-mint);
}

.ant-input:not(.ant-input-disabled):hover,
.ant-input-hover,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity));
    --tw-ring-color: var(--light-mint);
    --tw-ring-offset-color: var(--light-mint);
    border-color: var(--light-mint);
}

.ant-picker-today-btn,
.ant-picker-cell-in-view.ant-picker-cell-today {
    color: var(--light-mint);
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid var(--light-mint);
    color: var(--light-mint);
}

.anticon.ant-input-clear-icon,
.ant-input-clear-icon {
    display: grid;
}