.LoginPage {
    text-align: center;
    background: #10aee5;
}

.BlobTop,
.BlobBottom {
    position: fixed;
    right: 0;
    width: 40%;
}

.BlobTop {
    top: 0;
}

.BlobBottom {
    bottom: 0;
}

.Illustration {
    width: 470px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
    margin-left: 80px;
}

.LoginFields {
    color: white;
    text-align: left;
    width: 45%;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
    padding: 50px;

    h1 {
        color: white;
        font-size: 48px;
        font-weight: 700;
        line-height: 65px;
        margin: 0;
    }

    h3 {
        color: white;
        font-size: 32px;
        line-height: 40x;
        margin: 0;
    }

    a {
        width: 100%;
        display: inline-block;
        padding: 20px;
        border-radius: 10px;
        text-align: center;
        color: #2b2a2a;
        font-size: 14px;
        background-color: white;
        margin-top: 22px;
    }
}

.GoogleLogo {
    width: 28px;
    margin-right: 14px;
}

.AngkasLogo {
    height: 40px;
}
