@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --charcoal-grey: #394242;
    --battleship-grey: #6c7373;
    --menu-link-color: #acacac;
    --menu-link-active: #444444;
    --light-torquise: #63cfcd;
    --dark-mint: #0f7ea7;
    --light-mint: #0b82ac;
    --light-mint-hovered: #095d7b;
    --ultralight-mint: #d7f5f4;
}

.ant-menu-horizontal {
    width: 100%;
}

html,
body {
    font-size: 12pt;
    background-color: #faf8f8 !important;
}

code {
    background: whitesmoke;
    padding: 1px 3px;
    color: gray;
    border-radius: 2px;
}
