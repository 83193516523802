.Banner {
    width: 100%;
}

.dashboard_smoke {
    width: 400px;
    filter: contrast(0.5);
}

.dashboard_biker {
    position: absolute;
    bottom: 0;
    right: 50px;
    width: 220px;
    z-index: 10;
}

.title,
.subtitle {
    text-align: left;
    font-size: 24px;
    font-weight: 200;
    margin: 0;
    padding: 0;
}

.title {
    font-size: 44px;
    color: #13aee5;
    font-weight: 300;
}

.subtitle {
    font-size: 22px;
    color: #9d9d9d;
    font-weight: 300;
}
