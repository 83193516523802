@use "styles/mixins.scss";

/* Add Below Classes Take from Ant Design then override the styles */

.ant-table,
.ant-select-item,
.ant-select,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    font-size: 13px;
}

.anticon {
    vertical-align: middle;
}

.ant-btn.ant-btn-primary {
    background: var(--light-mint);
    border-color: var(--dark-mint);
    color: white;
}

.ant-btn.ant-btn-primary:hover {
    background: var(--light-mint-hovered);
    border-color: var(--dark-mint);
    color: white;
}

.ant-menu-horizontal > .ant-menu-item a,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item span {
    color: var(--menu-link-color);
    text-transform: uppercase;
    font-size: var(--default-font-size);
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected,
.ant-menu-horizontal > .ant-menu-item a:hover,
.ant-menu-horizontal > .ant-menu-item span:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected span {
    color: var(--menu-link-active);
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: solid 2px var(--default-border-color);
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    padding: 0 10px;
}

.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
    right: 10px;
    left: 10px;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
    border-right: none;
}

.ant-menu-inline.ant-menu-root .ant-menu-item,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    font-size: 12px;
}

.ant-menu-horizontal {
    width: 100%;
    line-height: 28px;
    border-bottom: none;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: var(--ultralight-mint);
    color: var(--light-mint);
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:hover,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu:hover .ant-menu-submenu-title,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu:hover .ant-menu-submenu-arrow {
    color: var(--light-mint);
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid var(--light-torquise);
}

.ant-menu-item-selected {
    color: var(--menu-link-active);
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    padding: 10px;
}

.ant-table-pagination.ant-pagination {
    margin: 32px 0;
    font-size: var(--default-font-size);
}

.ant-table.ant-table-empty {
    .ant-table-thead {
        display: none;
    }

    .ant-table-tbody .ant-table-placeholder {
        .ant-table-cell {
            background-color: var(--default-background-empty);
            border: none;
            border-radius: var(--default-border-radius);

            &:hover {
                background-color: var(--default-background-empty) !important;
            }
        }
    }
}

.ant-checkbox-checked .ant-checkbox-inner {
    background: var(--light-mint);
    border-color: var(--dark-mint);
}

.ant-input,
.ant-picker,
.ant-input-affix-wrapper,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    @include mixins.borders;
}

.ant-input,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid var(--default-border-color);
}

.ant-layout-sider-trigger {
    position: fixed;
    top: 0;
    z-index: 1;
    height: 48px;
    color: #fff;
    line-height: 48px;
    text-align: center;
    background: #002140;
    cursor: pointer;
    transition: all 0.2s;
}

.ant-notification {
    margin-right: 330px;
}

.ant-upload.ant-upload-select-picture-card {
    margin: 0 !important;
}
.ant-form-inline .ant-form-item-with-help {
    margin-bottom: 0 !important;
}

.ant-form-inline .ant-form-item {
    margin-right: 5px !important;
}

.card-collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-header-text {
    --tw-text-opacity: 1;
    color: rgb(122 121 132 / var(--tw-text-opacity));
    text-transform: uppercase;
    margin: 0;
}

.card-collapse.ant-collapse {
    .ant-collapse-header {
        padding: 0 !important;
    }

    .ant-collapse-content-box {
        padding: 0;
        padding-bottom: 0 !important;
    }
}

.ant-tag.whitespace-break-spaces {
    white-space: break-spaces;
}

.ant-menu-root.ant-menu-inline {
    border: none;
}

.main-layout {
    &.ant-layout {
        background: white;
    }
}

.ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 22px !important;
}

.audit-logs-table {
    .ant-table-cell p {
        margin-bottom: 0px !important;
    }
}
